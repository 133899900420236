import * as React from 'react'
import debounce from 'lodash.debounce'
import { StoreContext } from '../../../context/store-context'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'
import { formatPrice } from '../../../utils/formatPrice'
import NumericInput from '../../NumericInput/NumericInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link, graphql, useStaticQuery } from 'gatsby'

export interface ILineItemProps {
  id: string
  title?: string
  quantity?: number
  variant?: {
    title: string
    image: {
      src: string
    }
    priceV2?: {
      currencyCode: string
      amount: number
    }
    product: {
      handle: string
    }
    id?: string
  }
}

export default function LineItem({ item }: { item: ILineItemProps }) {
  const { removeLineItem, checkout, updateLineItem } =
    React.useContext(StoreContext)

  const { allShopifyProductVariant } = useStaticQuery(query)

  const quantityInStock = allShopifyProductVariant.nodes.find(
    (variant: { storefrontId?: string }) =>
      variant.storefrontId === item.variant?.id
  ).inventoryQuantity

  const [quantity, setQuantity] = React.useState<number>(
    item.quantity ? item.quantity : 1
  )

  const variantImage = {
    ...item.variant?.image,
    originalSrc: item.variant?.image.src,
    altText: item.variant?.title,
  }

  const price = formatPrice(
    String(item.variant?.priceV2?.currencyCode),
    Number(item.variant?.priceV2?.amount)
  )

  const subtotal = formatPrice(
    String(item.variant?.priceV2?.currencyCode),
    Number(item.variant?.priceV2?.amount) * quantity
  )

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id)
  }

  const uli = debounce(
    (value) => updateLineItem(checkout.id, item.id, value),
    300
  )

  const debouncedUli = React.useCallback((value: number) => uli(value), [])

  const handleQuantityChange = (value: string | number) => {
    if (value !== '' && Number(value) < 1) {
      return
    }
    if (!isNaN(Number(value)) && Number(value) <= quantityInStock) {
      setQuantity(Number(value))
      if (Number(value) >= 1) {
        debouncedUli(Number(value))
      }
    }
  }

  function handleIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1)
  }

  function handleDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1)
  }

  const image = React.useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: 'constrained',
        crop: 'contain',
        width: 160,
        height: 160,
      }),
    [variantImage.src]
  )

  return (
    <tr data-testid="cart-line-item">
      <td>
        {image && (
          <div className="w-24 bg-grey-300 p-1">
            <Link
              aria-label={item.variant?.title}
              to={`/shop/products/${item.variant?.product.handle}`}
              data-testid="line-item-image-link"
            >
              <GatsbyImage
                key={variantImage.src}
                image={image}
                alt={variantImage.altText ?? String(item.variant?.title)}
              />
            </Link>
          </div>
        )}
      </td>
      <td>
        <h2 className="text-lg">{item.title}</h2>
        <h3 className="text-base font-normal">
          {item.variant?.title === 'Default Title' ? '' : item.variant?.title}
        </h3>
        <div className="mt-2">
          <button onClick={handleRemove}>
            <FontAwesomeIcon
              className="text-error-600"
              icon={faTrash as IconProp}
            />{' '}
            Remove
          </button>
        </div>
      </td>
      <td>{price}</td>
      <td>
        <NumericInput
          label={`${item.variant?.title} quantity`}
          testId={`${item.variant?.product.handle}-numeric-input`}
          value={quantity}
          aria-label="Quantity"
          onIncrement={handleIncrement}
          onDecrement={handleDecrement}
          onChange={(e: { currentTarget: { value: string | number } }) =>
            handleQuantityChange(e.currentTarget.value)
          }
          maxValue={quantityInStock}
        />
      </td>
      <td>{subtotal}</td>
    </tr>
  )
}

const query = graphql`
  query GetProductVariants {
    allShopifyProductVariant(filter: { inventoryQuantity: { gt: 0 } }) {
      nodes {
        storefrontId
        inventoryQuantity
      }
    }
  }
`
