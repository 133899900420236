import * as React from 'react'
import Layout from 'components/Layout'

import CartPage from '../../components/Shop/CartPage/CartPage'
import Seo from '../../components/Seo'

const Cart = () => {
  return (
    <Layout>
      <CartPage />
    </Layout>
  )
}

export default Cart

export const Head = () => {
  return (
    <Seo
      title="Cart"
      description="Take part in Xanadu's mission to build quantum computers that are useful and available to people everywhere"
      image="company_10.png"
    />
  )
}
