import * as React from 'react'
import './Toast.css'

interface IToastProps {
  show: boolean
  children: JSX.Element
  duration?: number
  className?: string
}

export default function Toast(props: IToastProps) {
  const { show, duration = 1000, className = '', children } = props
  const [isVisible, setIsVisible] = React.useState(show)

  React.useEffect(() => {
    if (show) {
      setIsVisible(true)
    }
    const timeout = setTimeout(() => {
      setIsVisible(show)
    }, duration)
    return () => clearTimeout(timeout)
  }, [show, duration])

  return isVisible ? (
    <div
      data-testid="toast-wrapper"
      className={`ToastWrapper ${show ? 'showing' : 'hiding'} ${className}`}
    >
      {children}
    </div>
  ) : null
}
