import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import './NumericInput.css'

interface INumericInputProps {
  value: number
  onIncrement: () => void
  onDecrement: () => void
  onChange: (e: {
    currentTarget: {
      value: string | number
    }
  }) => void
  label: string
  testId?: string
  maxValue?: number
}

export default function NumericInput(props: INumericInputProps) {
  const {
    value,
    onIncrement,
    onDecrement,
    onChange,
    label,
    testId = 'numeric-input',
    maxValue,
  } = props

  return (
    <div className="NumericInput__container" data-testid={testId}>
      <label htmlFor="numeric-input" className="sr-only">
        {label}
      </label>
      <input
        id="numeric-input"
        className="NumericInput__input"
        value={value}
        type="numeric"
        onChange={onChange}
        data-testid="numeric-input-input"
        disabled={value === 1 && maxValue === 1}
      />
      <button
        className={`NumericInput__increment ${
          value === maxValue ? 'text-grey-500 cursor-not-allowed' : ''
        }`}
        aria-label="Increment"
        onClick={onIncrement}
        data-testid="increment"
        disabled={value === maxValue}
      >
        <FontAwesomeIcon icon={faChevronUp as IconProp} />
      </button>
      <button
        className={`NumericInput__decrement ${
          value === 1 ? 'text-grey-500 cursor-not-allowed' : ''
        }`}
        aria-label="Decrement"
        onClick={onDecrement}
        data-testid="decrement"
        disabled={value === 1 && maxValue === 1}
      >
        <FontAwesomeIcon icon={faChevronDown as IconProp} />
      </button>
    </div>
  )
}
